import moment from 'moment';
import { PrescriptionService } from '@/services/prescription.service';
import { getUnixTime, parse, getHours, getMinutes, getSeconds, format, fromUnixTime } from 'date-fns';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    showDialog(show) {
      if (!show) {
        this.alert.show = false;
      } else {
        var _this$rowData;
        if ((_this$rowData = this.rowData) !== null && _this$rowData !== void 0 && _this$rowData.resumecollection) {
          this.selectedOption = 'Set a Date';
          this.dateForResume = this.parseUnixDate(this.rowData.resumecollection, 'yyyy-MM-dd');
        }
      }
    }
  },
  computed: {
    tomorrow() {
      return new moment().add(1, 'd').format('YYYY-MM-DD');
    }
  },
  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      buttonDisabled: false,
      selectedOption: '',
      dateForResume: ''
    };
  },
  methods: {
    async pausePrescription() {
      this.alert.show = false;
      this.buttonDisabled = true;
      try {
        // With date to resume
        if (this.selectedOption == 'Set a Date') {
          if (!this.dateForResume) {
            throw 'Please input a date.';
          }
          const resumeDate = getUnixTime(parse(this.getDateStringWithCurrentTime(this.dateForResume), 'yyyy-MM-dd H:m:s', new Date()));
          const payload = {
            resumecollection: resumeDate
          };
          const {
            data
          } = await PrescriptionService.pausePrescription(this.rowData.id, payload);
          if (data) {
            this.buttonDisabled = false;
            this.$emit('set-prescription-status', this.rowData.id, 'PAUSED');
            this.$emit('refresh-prescriptions');
            this.showDialog = false;
          }
          // Indefinite
        } else {
          const {
            data
          } = await PrescriptionService.pausePrescription(this.rowData.id);
          if (data) {
            this.buttonDisabled = false;
            this.$emit('set-prescription-status', 'PAUSED');
            this.$emit('refresh-prescriptions');
            this.showDialog = false;
          }
        }
      } catch (err) {
        this.buttonDisabled = false;
        this.alert.message = err;
        this.alert.show = true;
      }
    },
    getDateStringWithCurrentTime(dateString) {
      const currentDate = new Date();
      return `${dateString} ${getHours(currentDate)}:${getMinutes(currentDate)}:${getSeconds(currentDate)}`;
    },
    parseUnixDate(date, formatString) {
      return format(fromUnixTime(date), formatString);
    }
  }
};